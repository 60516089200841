<template>
  <div class="agent--info-container" v-if="listing && listing.owner">
    <div class="user--and-profile">
      <img
        class="user--profile"
        :src="listing.owner.photo_profile_user"
        :alt="listing.owner.name"
      />
      <div class="user--section">
        <!--        <div class="posted&#45;&#45;at" v-if="listing.posted_at">-->
        <!--          <v-icon>mdi-clock-outline</v-icon>-->
        <!--          <span>{{-->
        <!--            `${$date.formatDistanceToNow(this.$date.parse(listing.posted_at))} ${$t('general.ago')}`-->
        <!--          }}</span>-->
        <!--        </div>-->
        <div class="user--name multiline-ellipsis-3" style="word-break: break-all">
          {{ listing.owner.name }}
        </div>
      </div>
    </div>
    <div class="d-flex align-center">
      <div v-if="listing.featured_agent" class="featured--agent-label">
        <img class="label--bg" src="@/assets/img/labels/ribbon-label.png" />
        <!--        <div class="label&#45;&#45;text">-->
        <!--          {{ $t('general.featuredAgent') }}-->
        <!--        </div>-->
      </div>
      <div class="office--section" v-if="listing.owner.agent_office_logo_url">
        <img
          class="office--logo"
          :src="listing.owner.agent_office_logo_url"
          :alt="listing.owner.agent_office_logo_name"
        />
        <!--      <div class="office&#45;&#45;name">{{ listing.owner.agent_office_logo_name }}</div>-->
      </div>
      <div class="office--section" v-else-if="listing.owner.agent_type_id === 1">
        <img class="office--logo" :src="agentOfficeIndependent" :alt="listing.owner.agent_type" />
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  mixins: [HelperMixin],
  components: {},
  props: {
    listing: {
      default: null,
      require: true,
    },
  },
  data: () => ({
    debounceCompare: null,
  }),
  computed: {
    ...mapState({
      agentOfficeIndependent: (state) => state.global.agentOfficeIndependent,
    }),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.agent--info-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $font-sm;
  color: $color-text-black;
  .user--and-profile {
    display: flex;
    align-items: center;
    @media #{$phones} {
      margin-top: 4px;
    }
  }
  .user--profile {
    width: 42px;
    min-width: 42px;
    height: 42px;
    object-fit: cover;
    margin-right: 8px;
    border-radius: 50%;
    border: solid 1px $color-gray-1;
    @media #{$phones} {
      margin-right: 6px;
    }
  }
  .user--section {
    margin-right: 8px;
    @media #{$phones} {
      margin-right: 6px;
    }
    .posted--at {
      margin-bottom: 2px;
      .v-icon {
        font-size: $font-md;
      }
      span {
        font-size: $font-xs;
      }
    }
    .user--name {
      @media #{$phones} {
        font-size: $font-xs;
      }
    }
  }
  .featured--agent-label {
    // TODO
    position: relative;
    width: 200px;
    height: 42px;
    .label--bg {
      top: 0;
      left: 0;
      position: absolute;
      width: 200px;
      height: 42px;
      object-fit: contain;
    }
    .label--text {
      top: 6px;
      left: 0;
      position: absolute;
      width: 200px;
      text-align: center;
      z-index: 5;
    }
  }
  .office--section {
    display: flex;
    align-items: center;
    @media #{$phones} {
      margin-top: 4px;
    }
  }
  .office--logo {
    height: 42px;
    object-fit: contain;
    //margin-right: 8px;
  }
}
</style>
